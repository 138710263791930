<template>
	<div class="textarea-skeleton">
		<Skeleton
			height="10px"
			width="10%" />

		<Skeleton
			height="10px"
			width="20%" />

		<Skeleton
			height="10px"
			width="40%" />
	</div>
</template>

<style scoped lang="scss">
	@import "textarea-skeleton";
</style>
